export const useSuccessStories = async () => {
  const storyblokApiOptions = useStoryblokApiOptions();
  const storyblokApi = useStoryblokApi();
  const { localeStoryName, goto, getUrlFromLink } = useUtils();

  const config = await useConfig();

  const { data } = await storyblokApi.get("cdn/stories", {
    ...storyblokApiOptions,
    starts_with: "use-cases/",
    is_startpage: 0,
  });

  const _blockquotes = useBlockquotes();
  const quotes = ref([]);
  quotes.value = await _blockquotes.all();

  const successStories = computed(() => {
    return data.stories.map((story) => {
      const quote = quotes.value.find(
        (quote) => quote.uuid === story.content.quote?.[0],
      );
      story.content.tags = story.content.tags?.split("\n") ?? [];
      return {
        ...story,
        quote,
      };
    });
  });

  function show(successStory: Object) {
    goto(
      getUrlFromLink({
        url: successStory.full_slug,
        linktype: "story",
      }),
    );
  }

  const linkText = (successStory: Object) => {
    return config.more_about_link_text.replace("{label}", successStory.name);
  };

  const link = (successStory: Object) => {
    return {
      link: {
        url: successStory.full_slug,
        linktype: "story",
      },
      linkText: linkText(successStory),
      button: true,
    };
  };

  return {
    successStories,
    show,
    link,
  };
};
